import { gql } from "@apollo/client";
import { mergeAndDedupeRefsById } from "../utils/object";

export const briefsTypePolicy = {
  keyArgs: false,
  merge: mergeAndDedupeRefsById,
  // read: (briefs, { args, readField }) => {
  //   const liveStatus = args?.input?.filter?.live?.eq;
  //   if (
  //     briefs === undefined
  //   ) {
  //     return briefs;
  //   }
  //   if (liveStatus !== undefined) {
  //     return briefs.filter((brief) => readField('live', brief) === liveStatus);
  //   }
  //   return briefs;
  // },
};

const listLiveByBrandId = gql`
  query ($brandId: String!, $live: Boolean!, $offset: Int!, $limit: Int!) {
    briefs(
      input: {
        filter: { brandId: { eq: $brandId }, live: { eq: $live } }
        pagination: { limit: $limit, offset: $offset }
      }
    ) {
      id
      submitted
      title
      deadline
      image
      live
      insightCount
      viewCount
    }
  }
`;

const listDeadlineLiveByBrandId = gql`
  query ($brandId: String!, $live: Boolean!, $deadline: String!, $offset: Int!, $limit: Int!) {
    briefs(
      input: {
        filter: {
          brandId: { eq: $brandId }
          setLiveAt: { lte: $deadline }
          live: { eq: $live }
          deadline: { gte: $deadline }
        }
        pagination: { limit: $limit, offset: $offset }
      }
    ) {
      id
      submitted
      title
      deadline
      image
      live
      setLiveAt
      insightCount
      viewCount
      isDirectlyTargeted
      brand {
        id
      }
    }
  }
`;

const listByBrandId = gql`
  query ($brandId: String!, $offset: Int!, $limit: Int!) {
    briefs(
      input: {
        filter: { brandId: { eq: $brandId }, deleted: { eq: true } }
        pagination: { limit: $limit, offset: $offset }
      }
    ) {
      id
      title
      deadline
      image
      live
      insightCount
      viewCount
    }
  }
`;

const list = gql`
  query ($offset: Int!, $limit: Int!) {
    briefs(
      input: { filter: { deleted: { eq: true } }, pagination: { limit: $limit, offset: $offset } }
    ) {
      id
      title
      deadline
      image
      live
      insightCount
      viewCount
    }
  }
`;

const get = gql`
  query ($briefId: String!) {
    brief(input: { briefId: $briefId }) {
      id
      brandId
      deadline
      details
      example
      updatedAt
      image
      insightsLimit
      nda
      live
      hidden
      setLiveAt
      cancelled
      submitted
      insightCount
      interests
      ethnicity
      country
      location
      gender
      minAge
      maxAge
      questions {
        id
        title
        subtitle
        blank
        type
        index
        minCharacters
        maxCharacters
        allowAttachments
        next
        multiple
        min
        max
        attachment
        attachment2
        attachment3
        attachment4
        attachment5
      }
      summary
      title
      brand {
        bio
        id
        name
        coverImage
        logoImage
      }
      winnerSelectionTime
      waitingList
      genderMaxType
      genderMaxPercentage
      countryMaxPercentage
      countryMaxType
    }
  }
`;

const getAuthClient = gql`
  query ($briefId: String!) {
    brief(input: { briefId: $briefId }) {
      id
      deadline
      details
      example
      updatedAt
      image
      insightsLimit
      nda
      live
      hidden
      setLiveAt
      cancelled
      submitted
      insightCount
      interests
      ethnicity
      country
      location
      gender
      minAge
      maxAge
      questions {
        id
        title
        subtitle
        blank
        type
        index
        minCharacters
        maxCharacters
        allowAttachments
        next
        multiple
        min
        max
        attachment
        attachment2
        attachment3
        attachment4
        attachment5
        business
        secret
        location
        gender
        age
        responses
        unallocated
        genderMaxType
        genderMaxPercentage
        countryMaxPercentage
        countryMaxType
      }
      summary
      title
      brand {
        bio
        name
        coverImage
        logoImage
      }
      winnerSelectionTime
      waitingList
      genderMaxType
      genderMaxPercentage
      excludeEmails
      countryMaxPercentage
      countryMaxType
    }
  }
`;

const add = gql`
  mutation (
    $brandId: String!
    $deadline: String!
    $details: String!
    $example: String
    $image: String
    $nda: String
    $insightsLimit: Int!
    $questions: [QuestionInput!]!
    $summary: String
    $title: String!
    $ethnicity: String
    $interests: String
    $gender: String
    $minAge: Int
    $maxAge: Int
    $country: String
    $location: String
    $setLiveAt: Date
    $genderMaxType: String
    $genderMaxPercentage: Int
    $countryMaxPercentage: Int
    $countryMaxType: String
  ) {
    addBrief(
      input: {
        brandId: $brandId
        deadline: $deadline
        details: $details
        example: $example
        image: $image
        insightsLimit: $insightsLimit
        nda: $nda
        interests: $interests
        live: false
        questions: $questions
        summary: $summary
        title: $title
        ethnicity: $ethnicity
        country: $country
        location: $location
        gender: $gender
        minAge: $minAge
        maxAge: $maxAge
        setLiveAt: $setLiveAt
        genderMaxType: $genderMaxType
        genderMaxPercentage: $genderMaxPercentage
        countryMaxPercentage: $countryMaxPercentage
        countryMaxType: $countryMaxType
      }
    ) {
      id
      deadline
      details
      example
      image
      insightsLimit
      nda
      setLiveAt
      live
      questions {
        id
        title
        subtitle
        blank
        type
        index
        minCharacters
        maxCharacters
        allowAttachments
        next
        multiple
        min
        max
        attachment
        attachment2
        attachment3
        attachment4
        attachment5
        business
        secret
        location
        gender
        age
        responses
        unallocated
        genderMaxType
        genderMaxPercentage
        countryMaxPercentage
        countryMaxType
      }
      summary
      title
      ethnicity
      interests
      country
      location
      gender
      minAge
      maxAge
      genderMaxType
      genderMaxPercentage
      countryMaxPercentage
      countryMaxType
    }
  }
`;

const update = gql`
  mutation (
    $briefId: String!
    $cancelled: Boolean!
    $deadline: String!
    $details: String!
    $example: String
    $nda: String
    $image: String
    $insightsLimit: Int!
    $live: Boolean!
    $questions: [EditQuestionInput!]!
    $summary: String
    $title: String!
    $interests: String
    $ethnicity: String
    $gender: String
    $minAge: Int
    $maxAge: Int
    $country: String
    $location: String
    $notify: Boolean!
    $emails: String
    $excludeEmails: String
    $setLiveAt: Date
    $genderMaxType: String
    $genderMaxPercentage: Int
    $countryMaxPercentage: Int
    $countryMaxType: String
  ) {
    editBrief(
      input: {
        briefId: $briefId
        cancelled: $cancelled
        deadline: $deadline
        details: $details
        example: $example
        image: $image
        nda: $nda
        insightsLimit: $insightsLimit
        live: $live
        questions: $questions
        summary: $summary
        title: $title
        interests: $interests
        ethnicity: $ethnicity
        country: $country
        location: $location
        gender: $gender
        minAge: $minAge
        maxAge: $maxAge
        notify: $notify
        emails: $emails
        excludeEmails: $excludeEmails
        setLiveAt: $setLiveAt
        genderMaxType: $genderMaxType
        genderMaxPercentage: $genderMaxPercentage
        countryMaxPercentage: $countryMaxPercentage
        countryMaxType: $countryMaxType
      }
    ) {
      id
      updatedAt
      cancelled
      deadline
      details
      example
      image
      nda
      insightsLimit
      setLiveAt
      live
      summary
      title
      interests
      ethnicity
      country
      location
      gender
      minAge
      maxAge
      genderMaxType
      genderMaxPercentage
      countryMaxPercentage
      countryMaxType
      excludeEmails
      questions {
        id
        title
        subtitle
        blank
        type
        index
        minCharacters
        maxCharacters
        allowAttachments
        next
        multiple
        min
        max
        attachment
        attachment2
        attachment3
        attachment4
        attachment5
        business
        secret
        location
        gender
        age
        responses
        unallocated
        genderMaxType
        genderMaxPercentage
        countryMaxPercentage
        countryMaxType
      }
    }
  }
`;

const view = gql`
  mutation ($briefId: String!) {
    viewBrief(input: { briefId: $briefId }) {
      id
    }
  }
`;

const getAcceptBrief = gql`
  query ($briefId: String!) {
    getAcceptBrief(input: { briefId: $briefId }) {
      id
      status
      createdAt
      updatedAt
    }
  }
`;

const getAllAcceptBriefs = gql`
  query ($briefId: String!) {
    getAllAcceptBriefs(input: { briefId: $briefId }) {
      id
      status
      createdAt
      updatedAt
      consultant {
        address {
          address
        }
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const getExpired = gql`
  query {
    getExpired {
      id
      status
      createdAt
      updatedAt
    }
  }
`;

const acceptBrief = gql`
  mutation ($briefId: String!, $hcaptchaToken: String) {
    acceptBrief(input: { briefId: $briefId, hcaptchaToken: $hcaptchaToken }) {
      id
      status
      createdAt
      updatedAt
    }
  }
`;

const declineBrief = gql`
  mutation ($briefId: String!) {
    declineBrief(input: { briefId: $briefId }) {
      id
      status
      createdAt
      updatedAt
    }
  }
`;

const acknowledgeExpired = gql`
  mutation ($ids: [String!]!) {
    acknowledgeExpired(input: { ids: $ids }) {
      affectedCount
    }
  }
`;

const deleteBrief = gql`
  mutation ($briefId: String!) {
    deleteBrief(input: { briefId: $briefId }) {
      id
    }
  }
`;

const duplicateBrief = gql`
  mutation ($briefId: String!) {
    duplicateBrief(input: { briefId: $briefId }) {
      id
    }
  }
`;

const extendBrief = gql`
  mutation ($briefId: String!, $hours: Int!) {
    extendBrief(input: { briefId: $briefId, hours: $hours }) {
      id
    }
  }
`;

const getTargetValue = gql`
  query (
    $clientId: String!
    $ethnicity: String
    $interests: String
    $gender: String
    $minAge: Int
    $maxAge: Int
    $country: String
    $location: String
    $createdAtStart: String
    $createdAtEnd: String
  ) {
    getTargetValue(
      input: {
        clientId: $clientId
        ethnicity: $ethnicity
        interests: $interests
        country: $country
        location: $location
        gender: $gender
        minAge: $minAge
        maxAge: $maxAge
        createdAtStart: $createdAtStart
        createdAtEnd: $createdAtEnd
      }
    )
  }
`;

const getDailyTargetValue = gql`
  query (
    $clientId: String!
    $ethnicity: String
    $interests: String
    $gender: String
    $minAge: Int
    $maxAge: Int
    $country: String
    $location: String
    $createdAtStart: String
    $createdAtEnd: String
  ) {
    getDailyTargetValue(
      input: {
        clientId: $clientId
        ethnicity: $ethnicity
        interests: $interests
        country: $country
        location: $location
        gender: $gender
        minAge: $minAge
        maxAge: $maxAge
        createdAtStart: $createdAtStart
        createdAtEnd: $createdAtEnd
      }
    )
  }
`;

const getDailyNotificationTargetValue = gql`
  query (
    $clientId: String!
    $ethnicity: String
    $interests: String
    $gender: String
    $minAge: Int
    $maxAge: Int
    $country: String
    $location: String
    $createdAtStart: String
    $createdAtEnd: String
  ) {
    getDailyNotificationTargetValue(
      input: {
        clientId: $clientId
        ethnicity: $ethnicity
        interests: $interests
        country: $country
        location: $location
        gender: $gender
        minAge: $minAge
        maxAge: $maxAge
        createdAtStart: $createdAtStart
        createdAtEnd: $createdAtEnd
      }
    )
  }
`;

const dailyBrief = gql`
  query ($consultantId: String!) {
    dailyBrief(input: { consultantId: $consultantId }) {
      brief {
        id
        deadline
        details
        example
        updatedAt
        image
        insightsLimit
        insightCount
        nda
        live
        hidden
        setLiveAt
        cancelled
        submitted
        interests
        ethnicity
        country
        location
        gender
        minAge
        maxAge
        questions {
          id
          title
          subtitle
          type
          index
          minCharacters
          maxCharacters
          allowAttachments
          blank
          multiple
          next
          min
          max
          attachment
          attachment2
          attachment3
          attachment4
          attachment5
        }
        summary
        title
        brand {
          bio
          name
          coverImage
          logoImage
        }
        winnerSelectionTime
        waitingList
      }
      index
    }
  }
`;

export default {
  listLiveByBrandId,
  listDeadlineLiveByBrandId,
  listByBrandId,
  add,
  update,
  getAuthClient,
  get,
  view,
  list,
  acceptBrief,
  getAcceptBrief,
  getAllAcceptBriefs,
  getExpired,
  acknowledgeExpired,
  declineBrief,
  deleteBrief,
  duplicateBrief,
  extendBrief,
  getTargetValue,
  getDailyTargetValue,
  getDailyNotificationTargetValue,
  dailyBrief,
};
